import { useRecoilState, useRecoilValue } from "recoil";
import {
  atm_isAccountListLoading,
  atm_isAccountLinking,
  atm_yodleeAccessToken,
  atm_dataProviderId,
  atm_reconnectLoading,
  atm_refreshPlaidLinkToken,
  atm_linkAccountTokenLoading,
  atm_tokenForExistingAccountLoading,
} from "../../state/atoms";

import { atm_accounts, atm_accountsList } from "../../state/atomsAccountList";

import useTransactionsLanding from "./Transactions/useTransactionsLanding";
import useHoldingsLanding from "./Holdings/useHoldingsLanding";

import axios from "axios";
import { getAuthToken, getHeaders } from "src/global/central";

const useAccountList = () => {
  let data = {};

  const { fnsTransactions } = useTransactionsLanding();
  const { fnsHoldings } = useHoldingsLanding();

  const [accounts, setAccounts] = useRecoilState(atm_accounts);
  data.accounts = accounts;
  const [accountsList, setAccountsList] = useRecoilState(atm_accountsList);
  data.accountsList = accountsList;

  const [refreshedPlaidLinkToken, setRefreshedPlaidLinkToken] = useRecoilState(
    atm_refreshPlaidLinkToken
  );
  data.refreshedPlaidLinkToken = refreshedPlaidLinkToken;

  const [yodleeAccessToken, setYodleeAccessToken] = useRecoilState(
    atm_yodleeAccessToken
  );
  data.yodleeAccessToken = yodleeAccessToken;

  const dataProviderId = useRecoilValue(atm_dataProviderId);

  let [isAccountListLoading, setIsAccountListLoading] = useRecoilState(
    atm_isAccountListLoading
  );
  data.isAccountListLoading = isAccountListLoading;

  let [reconnectLoading, setReconnectLoading] =
    useRecoilState(atm_reconnectLoading);
  data.reconnectLoading = reconnectLoading;

  let [tokenForExistingAccountLoading, setTokenForExistingAccountLoading] =
    useRecoilState(atm_tokenForExistingAccountLoading);
  data.tokenForExistingAccountLoading = tokenForExistingAccountLoading;

  let [linkAccountTokenLoading, setLinkAccountTokenLoading] = useRecoilState(
    atm_linkAccountTokenLoading
  );
  data.linkAccountTokenLoading = linkAccountTokenLoading;

  let [isAccountLinking, setisAccountLinking] =
    useRecoilState(atm_isAccountLinking);
  data.isAccountLinking = isAccountLinking;

  // The usePlaidLink hook manages Plaid Link creation
  // It does not return a destroy function;
  // instead, on unmount it automatically destroys the Link instance

  const onAccountListPageLoad = (e) => {
    // console.log("inside onAccountListPageLoad");
    // if (dataProviderId === 2) getLinkToken();

    setAccountsList([]);
    getAccountsData();
  };

  function refreshAccountBalanceDataFromProvider(isPlaidUpdated) {
    setIsAccountListLoading(true);

    let endPoint = "";

    if (dataProviderId === 1)
      // Yodlee
      endPoint =
        process.env.REACT_APP_ACCOUNT_API_END_POINT +
        "/accounts/balance/v2/updateAccountBalanceFromProvider";
    else if (dataProviderId === 2)
      // Plaid
      endPoint =
        process.env.REACT_APP_ACCOUNT_API_END_POINT +
        "/accounts/balance/v1/updateAccountBalanceFromProvider";

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getAuthToken(),
      },
    };

    fetch(endPoint, requestOptions)
      .then((response) => {
        if (!response.ok) {
          if (response.status == "401") {
            console.log("Unable to refresh account balance details");
          }
          throw response.text();
        }
        return response.json();
      })
      .then((responseData) =>
        processAccountsBalanceDataRefresh(responseData, isPlaidUpdated)
      )
      .catch((err) => {
        console.log("inside catch block with error : " + err);
      });
  }

  function processAccountsBalanceDataRefresh(response, isPlaidUpdated) {
    if (!response.success) {
      var errorMessage = response.responseMessage;
      console.log("Received error message : " + errorMessage);
    }
    getAccountsData();
    if (isPlaidUpdated) {
      fnsTransactions.refreshTransactionDataFromProvider();
      fnsHoldings.refreshHoldingsDataFromProvider();
    }
  }

  function getAccountsData() {
    setIsAccountListLoading(true);

    // axios work
    axios
      .post(
        process.env.REACT_APP_ACCOUNT_API_END_POINT +
          "/accounts/v1/getAllAccountBalances",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + getAuthToken(),
          },
        }
      )
      .then((res) => {
        // console.log(res["data"]["responseData"]);
        processAccountsDataResponse(res["data"]);
      })
      .catch((err) => {
        if (err.response.status == "401") {
          console.log("Unable to get account details");
        }
        console.log(err);
      });
  }

  function processAccountsDataResponse(response) {
    var accountsList = [];

    if (response.success != true) {
      var errorMessage = response.responseMessage;
      alert("Received error message : " + errorMessage);
    } else {
      accountsList = response.responseData["accountTypes"];
    }
    setAccounts(response.responseData);
    setAccountsList(accountsList);
    setIsAccountListLoading(false);
  }

  // ****************************************************
  // Kamunity Plaid Link
  // ****************************************************

  async function getLinkToken() {
    setLinkAccountTokenLoading(true);
    let api =
      process.env.REACT_APP_ACCOUNT_API_END_POINT +
      "/accounts/plaid/v1/getLinkToken";
    let requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getAuthToken(),
      },
    };
    try {
      let response = await axios.post(api, null, requestOptions);
      let linkToken = response.data.responseData;
      return linkToken;
    } catch (err) {
      console.log("Received error message : ", err);
    } finally {
      setLinkAccountTokenLoading(false);
    }
  }

  /// ************************************************************************
  //            GET THE YODLEE ACCESS TOKEN
  /// ************************************************************************

  function getYodleeAccessToken() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getAuthToken(),
      },
    };
    fetch(
      process.env.REACT_APP_ACCOUNT_API_END_POINT +
        "/accounts/provider/v2/getAuthToken",
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          if (response.status == "401") {
            console.log("Unable to get link token from server");
          }
          throw response.text();
        }
        return response.json();
      })
      .then((responseData) => processYodleeAccessTokenResponse(responseData))
      .catch((err) => {
        console.log("inside catch block with error : " + err);
      });
  }

  function processYodleeAccessTokenResponse(response) {
    var accessToken = "";
    if (!response.success) {
      var errorMessage = response.responseMessage;
      console.log("Received error message : " + errorMessage);
    } else {
      accessToken = response.responseData.accessToken;
      // console.log("Yodlee access token : " + accessToken)
    }
    setYodleeAccessToken(accessToken);
    // console.log("about to open the window .. + " + accessToken);

    window.fastlink.open(
      {
        fastLinkURL:
          "https://fl4.sandbox.yodlee.com/authenticate/restserver/fastlink",
        accessToken: accessToken,
        params: {
          configName: "Aggregation",
        },
        forceIframe: true,
        iframeScrolling: true,

        onSuccess: (data) => {
          // will be called on success. For a list of possible messages, refer to onSuccess(data) Method.
          // console.log(data);
        },
        onError: (data) => {
          // will be called on error. For a list of possible messages, refer to onError(data) Method.
          // console.log(data);
        },
        onClose: (data) => {
          // will be called to close FastLink. For a list of possible messages, refer to onClose(data) Method.
          if (data.sites.length > 0) registerNewYodleeAccounts(data);
        },
        onEvent: (data) => {
          // will be called on intermittent status update.
          // console.log(data);
        },
      },
      "container-fastlink"
    );

    // if (plaidConfig.token && ready) open();
    // else alert("Plaid link not ready");
  }

  function registerNewYodleeAccounts(data) {
    // setIsAccountListLoading(true);
    setisAccountLinking(true);

    for (let i = 0; i < data.sites.length; ++i) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAuthToken(),
        },
        body: JSON.stringify([
          {
            additionalStatus: "AVAILABLE_DATA_RETRIEVED",
            providerAccountId: data.sites[i].providerAccountId,
            providerId: data.sites[i].providerId,
            providerName: data.sites[i].providerName,
            requestId: data.sites[i].requestId,
            status: "SUCCESS",
          },
        ]),
      };

      fetch(
        process.env.REACT_APP_ACCOUNT_API_END_POINT +
          "/accounts/provider/v2/registerNewAccounts",
        requestOptions
      )
        .then((response) => {
          if (!response.ok) {
            if (response.status == "401") {
              console.log("Unable to get link token from server");
            }
            throw response.text();
          }
          return response.json();
        })
        .then((responseData) => registerNewYodleeAccountsResponse(responseData))
        .catch((err) => {
          console.log("inside catch block with error : " + err);
        });
    }
  }

  function registerNewYodleeAccountsResponse(response) {
    var linkToken = "";

    if (!response.success) {
      var errorMessage = response.responseMessage;
      console.log("Received error message : " + errorMessage);
    } else {
      setisAccountLinking(false);
    }
    refreshAccountBalanceDataFromProvider();
    fnsTransactions.onTransactionsLandingPageLoad();
    // fnsHoldings.onHoldingsLandingPageLoad();
    // //getSecuritiesByUserData();
  }

  function registerNewPlaidAccounts(public_token, metadata) {
    // setIsAccountListLoading(true);
    setisAccountLinking(true);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getAuthToken(),
      },
      body: JSON.stringify({
        publicToken: public_token,
        details: metadata,
      }),
    };

    fetch(
      process.env.REACT_APP_ACCOUNT_API_END_POINT +
        "/accounts/provider/v1/registerNewAccounts",
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          if (response.status == "401") {
            console.log("Unable to get link token from server");
          }
          throw response.text();
        }
        return response.json();
      })
      .then((responseData) => registerNewPlaidAccountsResponse(responseData))
      .catch((err) => {
        console.log("inside catch block with error : " + err);
      });
  }

  function registerNewPlaidAccountsResponse(response) {
    var linkToken = "";

    if (!response.success) {
      var errorMessage = response.responseMessage;
      console.log("Received error message : " + errorMessage);
    } else {
      //linkToken = response.responseData;
      // alert("Accounts successfully registered");
      // This is returning old data format. We need to call getAccountsData so that the new code executes.
      //setAccountsList(response.responseData);
      //setIsAccountListLoading(false);
      setisAccountLinking(false);
    }
    // getAccountsData();
    refreshAccountBalanceDataFromProvider();
    fnsTransactions.onTransactionsLandingPageLoad(true);
    fnsHoldings.onHoldingsLandingPageLoad(true);
    //getSecuritiesByUserData();
  }

  async function getAccessTokenForProblemItem(userItemId) {
    setReconnectLoading(true);
    let api =
      process.env.REACT_APP_ACCOUNT_API_END_POINT +
      "/accounts/provider/v1/getAccessTokenForProblemItem";
    let payload = { userItemId };
    let requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getAuthToken(),
      },
    };
    try {
      let response = await axios.post(api, payload, requestOptions);
      let linkToken = response.data.responseData;
      return linkToken;
    } catch (err) {
      console.log("Received error message : ", err);
    } finally {
      setReconnectLoading(false);
    }
  }

  async function getAccessTokenForExistingAccount(userItemAccountId) {
    setTokenForExistingAccountLoading(true);
    let api =
      process.env.REACT_APP_ACCOUNT_API_END_POINT +
      "/accounts/provider/v1/getAccessTokenForExistingAccount";
    let payload = { userItemAccountId };
    let requestOptions = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getAuthToken(),
      },
    };
    try {
      let response = await axios.post(api, payload, requestOptions);
      let linkToken = response.data.responseData;
      return linkToken;
    } catch (err) {
      console.log("Received error message : ", err);
    } finally {
      setTokenForExistingAccountLoading(false);
    }
  }

  let fns = {
    onAccountListPageLoad,
    getAccessTokenForProblemItem,
    getLinkToken,
    registerNewPlaidAccounts,
    refreshAccountBalanceDataFromProvider,
    getAccountsData,
    getAccessTokenForExistingAccount
  };
  return { data, fns };
};

export default useAccountList;
