import React, { useEffect } from "react";
import { Col, Row, Text } from "src/components/shared";
import KmnButton from "./KmnButton";
import BulkEditCategoryDropdown from "src/components/ManageCategories/BulkEditCategoryDropdown";
import { useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil";
import { atm_isAllSelectedInBulkEdit, atm_isBulkEditMode, atm_selectedDataForBulkEdit } from "src/state/atomsTransactionData";
import useManegeCategories from "src/components/ManageCategories/useManageCategories";

const BulkEditBox = () => {
  const {data, fnsManageCategories} = useManegeCategories();
  const [selectedDataForBulkEdit, setSelectedDataForBulkEdit] = useRecoilState(atm_selectedDataForBulkEdit);
  const selectedTransactions = selectedDataForBulkEdit?.transactionIds;
  const setIsBulkEditMode = useSetRecoilState(atm_isBulkEditMode);
  const resetIsAllSelectedInBulkEdit = useResetRecoilState(atm_isAllSelectedInBulkEdit);

  const isSaveDisabled = !selectedTransactions?.length ||!selectedDataForBulkEdit?.kmnCategoryMasterId

  const handleCancel = () => {
    setIsBulkEditMode(false);
  };

  const handleSave = () => {
    fnsManageCategories.updateCategory(selectedDataForBulkEdit)
  }

  useEffect(() => {
    return () => {
      setSelectedDataForBulkEdit(null);
      setIsBulkEditMode(false);
      resetIsAllSelectedInBulkEdit();
    };
  },[])
  
  return (
    <Row className="bulkEditBox" gap={20}>
      <header>
        <span>Bulk Edit</span>
      </header>
      <Col className="content" justify={"center"} gap={7}>
        <span className="selectedTransactions">Selected Transactions ({!selectedTransactions?.length ? 0 : selectedTransactions?.length})</span>
        <Row justify={"between"} align={"center"}>
          <BulkEditCategoryDropdown />
          <Row gap={12}>
            <KmnButton variant={"primary"} color="primary_outlined" onClick={handleCancel}>
              <Text size={14}>Cancel</Text>
            </KmnButton>
            <KmnButton isLoading={data.categoryUpdateLoading} variant={"primary"} color="primary" onClick={handleSave} isDisabled={isSaveDisabled}>
              <Text size={14} weight={700}>Update</Text>
            </KmnButton>
          </Row>
        </Row>
      </Col>
    </Row>
  );
};

export default BulkEditBox;
