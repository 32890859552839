import React, {
  memo,
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import DataGrid from "../DataGrid";
import { Row } from "src/components/shared";
import { LoaderIcon } from "src/libs/icons";

const InfiniteList = ({
  rows,
  columns,
  onLoadMore,
  isLoading,
  hasMore,
  totalRows,
  scrollerRef,
  ...otherProps
}) => {
  const [visibleRows, setVisibleRows] = useState([]);
  const [visibleCount, setVisibleCount] = useState(0);
  const [visibleStartIndex, setVisibleStartIndex] = useState(0);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const topObserverRef = useRef(null);
  const bottomObserverRef = useRef(null);
  const rowHeight = 90;
  const bufferSize = scrollerRef.current?.clientHeight / rowHeight + 10;
  
  const sortedData = useMemo(() => {
    let sortableData = [...rows];
    if (sortConfig.key !== null) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [rows, sortConfig]);

  const updateVisibleRows = () => {
    if (scrollerRef?.current) {
      const { scrollTop, clientHeight } = scrollerRef.current;
      const startIndex = Math.floor(scrollTop / rowHeight);
      const visibleCount = Math.ceil(clientHeight / rowHeight);

      const newStartIndex = Math.max(0, startIndex - bufferSize);
      const newEndIndex = Math.min(
        sortedData.length,
        startIndex + visibleCount + bufferSize
      );

      setVisibleStartIndex(newStartIndex);
      setVisibleRows(sortedData.slice(newStartIndex, newEndIndex));
      const newVisibleCount = Math.min(
        Math.ceil((startIndex + visibleCount) / 50) * 50,
        totalRows
      );
      setVisibleCount(newVisibleCount);
    }
  };

  useEffect(() => {
    updateVisibleRows();
  }, [rows, totalRows]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0,
    };

    const topObserver = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && visibleStartIndex > 0) {
        updateVisibleRows();
      }
    }, options);

    const bottomObserver = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && hasMore) {
        onLoadMore();
      }
    }, options);

    if (topObserverRef.current) {
      topObserver.observe(topObserverRef.current);
    }

    if (bottomObserverRef.current) {
      bottomObserver.observe(bottomObserverRef.current);
    }

    return () => {
      if (topObserverRef.current) {
        topObserver.unobserve(topObserverRef.current);
      }
      if (bottomObserverRef.current) {
        bottomObserver.unobserve(bottomObserverRef.current);
      }
    };
  }, [visibleStartIndex, hasMore, onLoadMore, visibleRows]);

  useEffect(() => {
    const handleScroll = () => {
      updateVisibleRows();
    };

    if (scrollerRef?.current) {
      scrollerRef.current.addEventListener("scroll", handleScroll, {
        passive: true,
      });
    }

    return () => {
      if (scrollerRef?.current) {
        scrollerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [updateVisibleRows]);

  return (
    <div
      style={{
        position: "relative",
        paddingTop: `${Math.floor(visibleStartIndex) * rowHeight}px`,
        paddingBottom: `${
          (rows.length - Math.floor(visibleStartIndex) - visibleRows.length) *
          rowHeight
        }px`,
      }}
    >
      <div ref={topObserverRef} style={{ height: "1px", width: "100%" }} />
      <DataGrid
        columns={columns}
        data={visibleRows}
        actualRows={rows}
        sortConfig={sortConfig}
        setSortConfig={setSortConfig}
        {...otherProps}
      />
      <div
        ref={bottomObserverRef}
        style={{
          height: rowHeight * 20,
          width: "100%",
          position: "absolute",
          bottom: 0,
          zIndex: -1,
        }}
      />
      {isLoading && (
        <Row
          justify="center"
          style={{
            background: "white",
            height: 30,
            bottom: 0
          }}
        >
          <img className="loader" src={LoaderIcon} alt="Loading..." />
        </Row>
      )}
      <div
        style={{
          position: "sticky",
          bottom: 0,
          padding: "10px",
          paddingBottom: 0,
          borderTop: "1px solid #e0e0e0",
          fontSize: "14px",
          background: "white",
          textAlign: "right",
        }}
      >
        {visibleCount || 0} of {totalRows} records
      </div>
    </div>
  );
};

export default memo(InfiniteList);
