import { useRecoilState } from "recoil";
import KmnModal from "../shared/KmnModal";
import { atm_showDeleteProfileModal } from "src/state/atomMyProfile";
import useDeviceType from "src/hooks/useDeviceType";
import { useState } from "react";
import { ErrorIcon } from "src/libs/icons";
import useApi from "src/global/useApi";
import useMainMenu from "../MainMenu/useMainMenu";

export default function DeleteProfileModal() {
  const { callApi: callDeleteUserApi, loading: deleteUserLoading, error } = useApi();
  const { fns } = useMainMenu();
  const { isMobile } = useDeviceType();
  const [showDeleteProfileModal, setShowDeleteProfileModal] = useRecoilState(atm_showDeleteProfileModal);
  const [errorScreen, setErrorScreen] = useState(false);

  const content = {
    description: errorScreen ? (
      <p className="message">
        <ErrorIcon className="errorIcon" />{error}
      </p>
    ) : (
      <div>
        <p className="message">Are you sure you want to delete your profile ?</p>
        <p className="message">You will not be able to log back in.</p>
      </div>
    ),
    confirmColor: errorScreen ? "primary" : "danger",
    confirmLabel: errorScreen ? "Ok" : "Confirm",
  };

  const handleConfirm = async () => {
    try {
      await callDeleteUserApi(`${process.env.REACT_APP_USER_API_END_POINT}/users/v1/removeUser`);
      fns.onMenuClickLogOut();
      setShowDeleteProfileModal(false);
    }catch (err) {
      setErrorScreen(true);
    }
  };

  const handleModalClose = () => {
    setShowDeleteProfileModal(false)
  }

  return (
    <KmnModal
      isOpen={showDeleteProfileModal}
      onClose={handleModalClose}
      onCancel={handleModalClose}
      onConfirm={errorScreen ? handleModalClose : handleConfirm}
      hideCancel={errorScreen}
      btnVariant={isMobile ? "secondary" : "tertiary"}
      confirmBtnLabel={content.confirmLabel}
      confirmBtnColor={content.confirmColor}
      cancelBtnColor="primary"
      title="Delete My Profile"
      body={content.description}
      className="deleteProfileModal"
      loading={deleteUserLoading}
      disableCancel={deleteUserLoading}
      disableConfirm={deleteUserLoading }
    />
  );
}
