import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { FILTER_LOADING_CONFIG } from "src/global/constants";
import { atm_displayNotification, atm_globalFilterValue } from "src/state/atoms";

const TagsFilter = ({ data, callBackFunction, loading }) => {
  const [selectedTags, setSelectedTags] = useState([]);
  const globalFilterValue = useRecoilValue(atm_globalFilterValue);
  const setDisplayNotification = useSetRecoilState(atm_displayNotification);

  const updateLocalDataWithGlobalData = () => {
    const { includedTagIds, excludedTagIds } =
      globalFilterValue.tagFilter;
    if (includedTagIds.length) {
      setSelectedTags(
        data.filter((d) => includedTagIds.includes(d.tagId))
      );
      return;
    } else if (excludedTagIds.length) {
      setSelectedTags(
        data.filter((d) => !excludedTagIds.includes(d.tagId))
      );
      return;
    } else {
      setSelectedTags([]);
    }
  };

  useEffect(() => {
    if (data.length) {
      updateLocalDataWithGlobalData();
    }
  }, [data]);

  const handleApply = (selections) => {
    if(loading) return setDisplayNotification(FILTER_LOADING_CONFIG);
    let includedIds = [];
    let exludedIds = [];
    
    if (!selections?.length) {
      callBackFunction(includedIds, exludedIds);
    } else {
      includedIds = selections.map((d) => d.tagId);
      callBackFunction(includedIds, exludedIds);
    }

    setSelectedTags(selections);
  };

  return (
    <div className="filterDropdown tagsFilterDropdowm">
      <span className="label">Tags</span>
      <Autocomplete
        multiple
        value={selectedTags}
        onChange={(_,tag) => handleApply(tag)}
        renderInput={(params) => <TextField {...params} label={!selectedTags.length ? "All" : null} />}
        getOptionLabel={(option) => option?.tagName}
        options={data}
        size="small"
        disableCloseOnSelect
      />
    </div>
  );
};

export default TagsFilter;
