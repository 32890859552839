export const SUBCATEGORY_MODE = {
    CREATE: "CREATE",
    EDIT: "EDIT",
    CONFIRM: "CONFIRM"
}

export const FILTER_LOADING_CONFIG = {
    open: true,
    message: "Please wait, we’re updating results!",
    type: "warning"
}