import React, { useState } from "react";
import TransListDropdown from "./TransListDropdown";
import InfiniteScroll from "react-infinite-scroll-component";
import useTransactionsLanding from "./useTransactionsLanding";
import Loader from "../../assets/images/loader.svg";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  atm_fetchMorePage,
  atm_isMoreDataLoaderActive,
} from "src/state/atomsTransactionData";
import { atm_globalMerchant } from "src/state/insightsMerchants";

const MobTransTable = ({ parentScroller, merchant }) => {
  const { dataTransactions, fnsTransactions } = useTransactionsLanding();
  const [hasMore, setHasMore] = useState(true);
  const [isLoaderActive, setIsLoaderActive] = useRecoilState(
    atm_isMoreDataLoaderActive
  );

  const [page, setPage] = useRecoilState(atm_fetchMorePage);
  const globalMerchant = useRecoilValue(atm_globalMerchant);

  const fetchMoreData = () => {
    if (
      dataTransactions.totalTransData.length <
      dataTransactions.allTransactionDataCount
    ) {
      setIsLoaderActive(true);
      fnsTransactions.getTransactionsData({pageNumber: page + 1, fetchMore: true, merchantId: merchant ? globalMerchant : null});
      setPage((prevPage) => prevPage + 1);
    } else {
      setHasMore(false);
    }
  };

  if (parentScroller.current && isLoaderActive) {
    parentScroller.current.scrollTop = parentScroller.current.scrollHeight;
  }
  return (
    <div
      id="mobTransTable"
      className="mobTransTable"
      ref={parentScroller}
      style={{ paddingBottom: isLoaderActive ? "50px" : 0 }}
    >
      <InfiniteScroll
        dataLength={dataTransactions.totalTransData.length}
        next={fetchMoreData}
        hasMore={hasMore}
        scrollableTarget="infinite-scroller"
        scrollThreshold={0.99}
        loader={<img className="loader" src={Loader} alt="Loading..." />}
      >
        {dataTransactions.totalTransData.map((transactionData, index) => {
          return (
            <TransListDropdown
              merchant={merchant}
              key={index}
              transactionData={transactionData}
            />
          );
        })}
      </InfiniteScroll>
    </div>
  );
};

export default MobTransTable;
