import { Box, LinearProgress, Modal, useMediaQuery } from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import useTransactionsLanding from "src/components/Accounts/Transactions/useTransactionsLanding";
import {
  atm_isReviewTransBoxOpen,
  atm_transactionsListTable,
} from "src/state/atomsTransactionData";
import useSpendByMerchant from "./useSpendByMerchant";
import CategoryChart from "./CategoryChart";
import MobTransTable from "src/components/Accounts/Transactions/MobTransTable";
import TransUserReview from "src/components/Accounts/Transactions/ParentReview";
import {
  atm_globalMerchant,
  atm_merchantParentSelectors,
} from "src/state/insightsMerchants";
import InfiniteList from "src/components/Accounts/Transactions/InfiniteList";

const MoM = () => {
  const mobile = useMediaQuery("(max-width: 900px)");
  const { data, fns } = useSpendByMerchant();
  const { dataTransactions, fnsTransactions } = useTransactionsLanding();
  const transactionsListTable = useRecoilValue(atm_transactionsListTable);
  const [isTransBoxOpen, setIsTransBoxOpen] = useRecoilState(
    atm_isReviewTransBoxOpen
  );

  const [currentPage, setCurrentPage] = useState(1);

  const hasMoreData =
  transactionsListTable?.length < dataTransactions.allTransactionDataCount;
  
  const modelStyle = {
    position: "relative",
    top: mobile ? "5%" : "10%",
    left: "50%",
    transform: "translateX(-50%)",
    maxWidth: "90%",
    width: 773,
  };

  const parentScroller = useRef(null);

  const loadMoreData = () => {
    if (!dataTransactions.isTransactionListLoading) {
      setCurrentPage((prevPage) => prevPage + 1);
      fnsTransactions.getTransactionsData({pageNumber: currentPage + 1,
        fetchMore: true,
        merchantId: globalMerchant,
      });
    }
  };

  const globalMerchant = useRecoilValue(atm_globalMerchant);

  const merchantParentSelectors = useRecoilValue(atm_merchantParentSelectors);

  useEffect(() => {
    if (!merchantParentSelectors.mom && data.merchantListData.length) {
      setCurrentPage(1);
      fns.refreshRightScreen(globalMerchant);
    }
  }, [merchantParentSelectors]);

  useLayoutEffect(() => {
    if((dataTransactions.isTransactionCountLoading || data.merchantListLoading) && parentScroller?.current) {
      setCurrentPage(1);
      parentScroller?.current?.scroll(0,0);
    }
  }, [dataTransactions.isTransactionCountLoading, data.merchantListLoading])

  return (
    <>
      <div
        className={mobile ? "scroller" : "scrollable-content scroller"}
        id="infinite-scroller"
        ref={parentScroller}
      >
        <div className="inside-scroller">
          {data.merchantChartLoading && (
            <LinearProgress
              style={{
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0,
              }}
            />
          )}
          <CategoryChart />
          {mobile ? (
            <MobTransTable merchant={true} parentScroller={parentScroller} />
          ) : (
            <div
              className="transactionTable"
              style={{
                width: "100%",
                position: "relative",
              }}
            >
              {dataTransactions.isTransactionListLoading && (
                <LinearProgress
                  style={{
                    width: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                />
              )}
              <InfiniteList
                rows={transactionsListTable}
                columns={data.clmnTransactions}
                onLoadMore={loadMoreData}
                hasMore={hasMoreData}
                isLoading={dataTransactions.isTransactionListLoading}
                getRowClassName={(params) => {
                  if (params.row.pending) {
                    return "pending";
                  }
                }}
                currentPage={currentPage}
                totalRows={dataTransactions.allTransactionDataCount}
                scrollerRef={parentScroller}
              />
            </div>
          )}
        </div>
      </div>
      <Modal open={isTransBoxOpen} onClose={() => setIsTransBoxOpen(false)}>
        <Box sx={modelStyle}>
          <TransUserReview />
        </Box>
      </Modal>
    </>
  );
};

export default MoM;
