import { atom } from "recoil";

export const atm_jwt = atom({
  key: "jwt",
  default: "",
});

export const atm_firstName = atom({
  key: "firstName",
  default: "",
});

export const atm_lastName = atom({
  key: "lastName",
  default: "",
});

export const atm_isUserLoggedIn = atom({
  key: "isUserLoggedIn",
  default: "",
});

export const atm_dataProviderId = atom({
  key: "dataProviderId",
  default: Number(localStorage.getItem("dataProvider")) || "",
});

export const atm_isAccountListLoading = atom({
  key: "isAccountListLoading",
  default: "",
});

export const atm_reconnectLoading = atom({
  key: "reconnectLoading",
  default: false,
});

export const atm_tokenForExistingAccountLoading = atom({
  key: "tokenForExistingAccountLoading",
  default: false,
});

export const atm_linkAccountTokenLoading = atom({
  key: "linkAccountTokenLoading",
  default: false,
});

export const atm_isAccountLinking = atom({
  key: "isAccountLinking",
  default: "",
});

export const atm_mainMenuButtonPressed = atom({
  key: "mainMenuButtonPressed",
  default:
    window.location.pathname === "/"
      ? "Home"
      : window.location.pathname.split("/")[1].charAt(0).toUpperCase() + window.location.pathname.split("/")[1].slice(1),
});

export const atm_menuInsightsType = atom({
  key: "menuInsightsType",
  default: "",
});

export const atm_refreshPlaidLinkToken = atom({
  key: "refreshPlaidLinkToken",
  default: "",
});

export const atm_yodleeAccessToken = atom({
  key: "yodleeAccessToken",
  default: "",
});

export const atm_WindowWidth = atom({
  key: "windowWidth",
  default: window.innerWidth,
});

export const atm_WindowHeight = atom({
  key: "windowHeight",
  default: window.innerHeight,
});

export const atm_globalFilterValue = atom({
  key: "globalFilterValue",
  default: {
    dateFilter: 12,
    categoryFilter: { selectedCategoryFilter: [], excludedCategoryFilter: [] },
    merchantFilter: { includeMerchantIds: [], excludeMerchantIds: [] },
    tagFilter: { includedTagIds: [], excludedTagIds: [] },
  },
});

export const atm_GlobalLoading = atom({
  key: "globalLoading",
  default: false,
});

export const atm_globalFilterCount = atom({
  key: "globalFilterCount",
  default: 1,
});

export const atm_isCategoryFilterModified = atom({
  key: "isCategoryFilterModified",
  default: false,
});

export const atm_displayNotification = atom({
  key: "displayNotification",
  default: null
})