import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Box,
  Select,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import "../assets/css/register.css";
import useMyProfile from "../MyProfile/useMyProfile";
import { ValidateEmail } from "src/validations/Email";
import { NameValidation } from "src/validations/Name";
import { atm_showDeleteProfileModal } from "src/state/atomMyProfile";
import KmnButton from "../Accounts/Transactions/KmnButton";
import { Row } from "../shared";
import { useSetRecoilState } from "recoil";

const Email = styled(TextField)({
  alignSelf: `stretch`,
  flexGrow: `1`,
  margin: `0px`,
});

const FirstName = styled(TextField)({
  alignSelf: `stretch`,
  flexGrow: `1`,
  margin: `0px`,
});

const LastName = styled(TextField)({
  alignSelf: `stretch`,
  flexGrow: `1`,
});

const PhoneNo = styled(TextField)({
  alignSelf: `stretch`,
  flexGrow: `1`,
  margin: `0px`,
});

const ZipCode = styled(TextField)({
  alignSelf: `stretch`,
  flexGrow: `1`,
});

const DOB = styled(Box)({
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  //   gridColumn: "1 / span 2",
  gap: 10,
  border: "1px solid rgb(0 0 0/0.23)",
  borderRadius: 4,
  height: 56,
  padding: "0 16px",
});

const DobLabel = styled(Box)({
  color: "rgb(0 0 0/0.6)",
  fontSize: 12,
  position: "absolute",
  top: 0,
  transform: "translateY(-50%)",
  left: 7,
  background: "#fff",
  padding: "2px 5px",
});

const ButtonContained = styled(Button)({
  margin: `0px`,
});

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const EditProfileForm = ({ toggleDrawer }) => {
  const { data, profileFns } = useMyProfile();
  const [values, setValues] = useState(data.profileData);
  
  const showDeleteProfileModal = useSetRecoilState(atm_showDeleteProfileModal)
  let [emailErorr, setEmailError] = useState(false);
  let [firstNameErorr, setFirstNameError] = useState(false);
  let [lastNameError, setLastNameError] = useState(false);
  let [zipCodeError, setZipCodeError] = useState(false);
  let [phoneNumberError, setPhoneNumberError] = useState(false);

  const numbers = /^[0-9]*$/;

  const days = Array.from({ length: 31 }, (_, index) => index + 1);

  const currentYear = new Date().getFullYear();

  const years = Array.from(
    { length: currentYear - 1944 + 1 },
    (_, index) => 1944 + index
  );

  useEffect(() => {
    setValues(data.profileData);
  }, [data.profileData]);

  const validateProfileDetails = () => {
    let isValid = true;
    if (!ValidateEmail(values?.email)) {
      setEmailError(true);
      // errors.email = true;
      isValid = false;
    }

    if (!NameValidation(values?.firstName)) {
      setFirstNameError(true);
      // errors.firstName = true;
      isValid = false;
    }

    if (!NameValidation(values?.lastName)) {
      setLastNameError(true);
      // errors.lastName = true;
      isValid = false;
    }

    if (values?.phoneNumber.length < 10) {
      setPhoneNumberError(true);
      // errors.phoneNumber = true;
      isValid = false;
    }

    if (values?.zipCode.length < 5) {
      setZipCodeError(true);
      // errors.zipCode = true;
      isValid = false;
    }

    return isValid;
  };

  const resetValidationState = (field) => {
    switch (field) {
      case "email":
        setEmailError(false);
        break;
      case "firstName":
        setFirstNameError(false);
        break;
      case "lastName":
        setLastNameError(false);
        break;
      case "zipCode":
        setZipCodeError(false);
        break;
      case "phoneNumber":
        setPhoneNumberError(false);
        break;
      default:
        break;
    }
  };

  const handleChange = (event) => {
    resetValidationState(event.target.name);
    setValues((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const canSubmit = validateProfileDetails();
    if (canSubmit) {
      profileFns.onClickSaveProfileDetails(values);
    }
  };

  const handleCancel = () => {
    setValues(data.profileData);
  };

  useEffect(() => {
    profileFns.getEditUserData();
  }, []);

  const handleDeleteProfile = (e) => {
    showDeleteProfileModal(true)
    e.preventDefault();
  }

  return (
    <form onSubmit={handleSubmit} className="editProfileForm">
      <div className="input-containers">
        <Email
          variant="outlined"
          className="grid-2"
          size="medium"
          onChange={(e) => {
            handleChange(e);
            profileFns.onChangeEmail(e);
          }}
          required
          label={`Email address`}
          type={"Email"}
          value={values?.email}
          error={Boolean((values?.email && emailErorr) || data.emailHelperText)}
          helperText={
            (emailErorr && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  color: "var(--color14)",
                }}
              >
                <svg
                  width="17"
                  height="17"
                  style={{
                    marginRight: "5px",
                  }}
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.5 0C6.81886 0 5.17547 0.498516 3.77766 1.43251C2.37984 2.3665 1.29037 3.69402 0.647028 5.24719C0.00368293 6.80036 -0.164645 8.50943 0.163329 10.1583C0.491303 11.8071 1.30085 13.3217 2.4896 14.5104C3.67834 15.6992 5.1929 16.5087 6.84174 16.8367C8.49057 17.1646 10.1996 16.9963 11.7528 16.353C13.306 15.7096 14.6335 14.6202 15.5675 13.2223C16.5015 11.8245 17 10.1811 17 8.5C17 6.24566 16.1045 4.08365 14.5104 2.48959C12.9163 0.895533 10.7543 0 8.5 0ZM7.44459 4.25C7.44459 3.96821 7.55653 3.69796 7.75578 3.4987C7.95504 3.29944 8.22529 3.1875 8.50709 3.1875C8.78888 3.1875 9.05913 3.29944 9.25839 3.4987C9.45764 3.69796 9.56959 3.96821 9.56959 4.25V9.13041C9.56959 9.26994 9.5421 9.40811 9.48871 9.53702C9.43531 9.66593 9.35705 9.78305 9.25839 9.88172C9.15972 9.98038 9.04259 10.0586 8.91369 10.112C8.78478 10.1654 8.64662 10.1929 8.50709 10.1929C8.36756 10.1929 8.22939 10.1654 8.10048 10.112C7.97158 10.0586 7.85445 9.98038 7.75578 9.88172C7.65712 9.78305 7.57886 9.66593 7.52546 9.53702C7.47207 9.40811 7.44459 9.26994 7.44459 9.13041V4.25ZM8.5 13.8125C8.25904 13.8125 8.02349 13.741 7.82313 13.6072C7.62278 13.4733 7.46662 13.283 7.37441 13.0604C7.2822 12.8378 7.25807 12.5928 7.30508 12.3565C7.35209 12.1201 7.46812 11.9031 7.63851 11.7327C7.8089 11.5623 8.02598 11.4463 8.26232 11.3992C8.49865 11.3522 8.74362 11.3764 8.96624 11.4686C9.18886 11.5608 9.37914 11.7169 9.51301 11.9173C9.64688 12.1176 9.71834 12.3532 9.71834 12.5942C9.71834 12.9173 9.58998 13.2272 9.36149 13.4557C9.13301 13.6841 8.82312 13.8125 8.5 13.8125Z"
                    fill="#E41616"
                  />
                </svg>{" "}
                Invalid Email
              </Box>
            )) ||
            data.emailHelperText
          }
          name="email"
          disabled={data.profileLoading}
        />

        <FirstName
          variant="outlined"
          size="medium"
          inputProps={{ maxLength: 30 }}
          onChange={(e) => {
            handleChange(e);
            profileFns.onChangeFirstName(e);
          }}
          onInput={(e) => {
            e.target.value = e.target.value.replace(/[^a-zA-Z]/g, "");
          }}
          required
          label={`First Name`}
          value={values?.firstName}
          error={Boolean(values?.firstName && firstNameErorr)}
          helperText={
            firstNameErorr && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  color: "var(--color14)",
                }}
              >
                <svg
                  width="17"
                  height="17"
                  style={{
                    marginRight: "5px",
                  }}
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.5 0C6.81886 0 5.17547 0.498516 3.77766 1.43251C2.37984 2.3665 1.29037 3.69402 0.647028 5.24719C0.00368293 6.80036 -0.164645 8.50943 0.163329 10.1583C0.491303 11.8071 1.30085 13.3217 2.4896 14.5104C3.67834 15.6992 5.1929 16.5087 6.84174 16.8367C8.49057 17.1646 10.1996 16.9963 11.7528 16.353C13.306 15.7096 14.6335 14.6202 15.5675 13.2223C16.5015 11.8245 17 10.1811 17 8.5C17 6.24566 16.1045 4.08365 14.5104 2.48959C12.9163 0.895533 10.7543 0 8.5 0ZM7.44459 4.25C7.44459 3.96821 7.55653 3.69796 7.75578 3.4987C7.95504 3.29944 8.22529 3.1875 8.50709 3.1875C8.78888 3.1875 9.05913 3.29944 9.25839 3.4987C9.45764 3.69796 9.56959 3.96821 9.56959 4.25V9.13041C9.56959 9.26994 9.5421 9.40811 9.48871 9.53702C9.43531 9.66593 9.35705 9.78305 9.25839 9.88172C9.15972 9.98038 9.04259 10.0586 8.91369 10.112C8.78478 10.1654 8.64662 10.1929 8.50709 10.1929C8.36756 10.1929 8.22939 10.1654 8.10048 10.112C7.97158 10.0586 7.85445 9.98038 7.75578 9.88172C7.65712 9.78305 7.57886 9.66593 7.52546 9.53702C7.47207 9.40811 7.44459 9.26994 7.44459 9.13041V4.25ZM8.5 13.8125C8.25904 13.8125 8.02349 13.741 7.82313 13.6072C7.62278 13.4733 7.46662 13.283 7.37441 13.0604C7.2822 12.8378 7.25807 12.5928 7.30508 12.3565C7.35209 12.1201 7.46812 11.9031 7.63851 11.7327C7.8089 11.5623 8.02598 11.4463 8.26232 11.3992C8.49865 11.3522 8.74362 11.3764 8.96624 11.4686C9.18886 11.5608 9.37914 11.7169 9.51301 11.9173C9.64688 12.1176 9.71834 12.3532 9.71834 12.5942C9.71834 12.9173 9.58998 13.2272 9.36149 13.4557C9.13301 13.6841 8.82312 13.8125 8.5 13.8125Z"
                    fill="#E41616"
                  />
                </svg>{" "}
                Invalid First Name
              </Box>
            )
          }
          name="firstName"
          disabled={data.profileLoading}
        />

        <LastName
          variant="outlined"
          size="medium"
          onChange={(e) => {
            handleChange(e);
            profileFns.onChangeLastName(e);
          }}
          required
          inputProps={{ maxLength: 50 }}
          onInput={(e) => {
            e.target.value = e.target.value.replace(/[^a-zA-Z]/g, "");
          }}
          label={`Last Name`}
          value={values?.lastName}
          error={Boolean(values?.lastName && lastNameError)}
          helperText={
            lastNameError && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  color: "var(--color14)",
                }}
              >
                <svg
                  width="17"
                  height="17"
                  style={{
                    marginRight: "5px",
                  }}
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.5 0C6.81886 0 5.17547 0.498516 3.77766 1.43251C2.37984 2.3665 1.29037 3.69402 0.647028 5.24719C0.00368293 6.80036 -0.164645 8.50943 0.163329 10.1583C0.491303 11.8071 1.30085 13.3217 2.4896 14.5104C3.67834 15.6992 5.1929 16.5087 6.84174 16.8367C8.49057 17.1646 10.1996 16.9963 11.7528 16.353C13.306 15.7096 14.6335 14.6202 15.5675 13.2223C16.5015 11.8245 17 10.1811 17 8.5C17 6.24566 16.1045 4.08365 14.5104 2.48959C12.9163 0.895533 10.7543 0 8.5 0ZM7.44459 4.25C7.44459 3.96821 7.55653 3.69796 7.75578 3.4987C7.95504 3.29944 8.22529 3.1875 8.50709 3.1875C8.78888 3.1875 9.05913 3.29944 9.25839 3.4987C9.45764 3.69796 9.56959 3.96821 9.56959 4.25V9.13041C9.56959 9.26994 9.5421 9.40811 9.48871 9.53702C9.43531 9.66593 9.35705 9.78305 9.25839 9.88172C9.15972 9.98038 9.04259 10.0586 8.91369 10.112C8.78478 10.1654 8.64662 10.1929 8.50709 10.1929C8.36756 10.1929 8.22939 10.1654 8.10048 10.112C7.97158 10.0586 7.85445 9.98038 7.75578 9.88172C7.65712 9.78305 7.57886 9.66593 7.52546 9.53702C7.47207 9.40811 7.44459 9.26994 7.44459 9.13041V4.25ZM8.5 13.8125C8.25904 13.8125 8.02349 13.741 7.82313 13.6072C7.62278 13.4733 7.46662 13.283 7.37441 13.0604C7.2822 12.8378 7.25807 12.5928 7.30508 12.3565C7.35209 12.1201 7.46812 11.9031 7.63851 11.7327C7.8089 11.5623 8.02598 11.4463 8.26232 11.3992C8.49865 11.3522 8.74362 11.3764 8.96624 11.4686C9.18886 11.5608 9.37914 11.7169 9.51301 11.9173C9.64688 12.1176 9.71834 12.3532 9.71834 12.5942C9.71834 12.9173 9.58998 13.2272 9.36149 13.4557C9.13301 13.6841 8.82312 13.8125 8.5 13.8125Z"
                    fill="#E41616"
                  />
                </svg>{" "}
                Invalid Last Name
              </Box>
            )
          }
          name="lastName"
          disabled={data.profileLoading}
        />

        <PhoneNo
          variant="outlined"
          size="medium"
          inputProps={{ maxLength: 10 }}
          onChange={(e) => {
            if (e.target.value.match(numbers)) {
              handleChange(e);
              profileFns.onChangePhoneNo(e);
            }
          }}
          required
          label={`Phone`}
          value={values?.phoneNumber}
          error={Boolean((values?.phoneNumber && phoneNumberError) || data.phoneNoHelperText)}
          helperText={(phoneNumberError && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                color: "var(--color14)",
              }}
            >
              <svg
                width="17"
                height="17"
                style={{
                  marginRight: "5px",
                }}
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.5 0C6.81886 0 5.17547 0.498516 3.77766 1.43251C2.37984 2.3665 1.29037 3.69402 0.647028 5.24719C0.00368293 6.80036 -0.164645 8.50943 0.163329 10.1583C0.491303 11.8071 1.30085 13.3217 2.4896 14.5104C3.67834 15.6992 5.1929 16.5087 6.84174 16.8367C8.49057 17.1646 10.1996 16.9963 11.7528 16.353C13.306 15.7096 14.6335 14.6202 15.5675 13.2223C16.5015 11.8245 17 10.1811 17 8.5C17 6.24566 16.1045 4.08365 14.5104 2.48959C12.9163 0.895533 10.7543 0 8.5 0ZM7.44459 4.25C7.44459 3.96821 7.55653 3.69796 7.75578 3.4987C7.95504 3.29944 8.22529 3.1875 8.50709 3.1875C8.78888 3.1875 9.05913 3.29944 9.25839 3.4987C9.45764 3.69796 9.56959 3.96821 9.56959 4.25V9.13041C9.56959 9.26994 9.5421 9.40811 9.48871 9.53702C9.43531 9.66593 9.35705 9.78305 9.25839 9.88172C9.15972 9.98038 9.04259 10.0586 8.91369 10.112C8.78478 10.1654 8.64662 10.1929 8.50709 10.1929C8.36756 10.1929 8.22939 10.1654 8.10048 10.112C7.97158 10.0586 7.85445 9.98038 7.75578 9.88172C7.65712 9.78305 7.57886 9.66593 7.52546 9.53702C7.47207 9.40811 7.44459 9.26994 7.44459 9.13041V4.25ZM8.5 13.8125C8.25904 13.8125 8.02349 13.741 7.82313 13.6072C7.62278 13.4733 7.46662 13.283 7.37441 13.0604C7.2822 12.8378 7.25807 12.5928 7.30508 12.3565C7.35209 12.1201 7.46812 11.9031 7.63851 11.7327C7.8089 11.5623 8.02598 11.4463 8.26232 11.3992C8.49865 11.3522 8.74362 11.3764 8.96624 11.4686C9.18886 11.5608 9.37914 11.7169 9.51301 11.9173C9.64688 12.1176 9.71834 12.3532 9.71834 12.5942C9.71834 12.9173 9.58998 13.2272 9.36149 13.4557C9.13301 13.6841 8.82312 13.8125 8.5 13.8125Z"
                  fill="#E41616"
                />
              </svg>{" "}
              Invalid Phone Number
            </Box>
          )) ||
          data.phoneNoHelperText}
          name="phoneNumber"
          disabled={data.profileLoading}
        />
        <ZipCode
          variant="outlined"
          size="medium"
          inputProps={{ maxLength: 5 }}
          onChange={(e) => {
            if (e.target.value.match(numbers)) {
              handleChange(e);
            }
          }}
          required
          label={`Zip Code`}
          value={values?.zipCode}
          error={Boolean(values?.zipCode && zipCodeError)}
          helperText={
            zipCodeError && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  color: "var(--color14)",
                }}
              >
                <svg
                  width="17"
                  height="17"
                  style={{
                    marginRight: "5px",
                  }}
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.5 0C6.81886 0 5.17547 0.498516 3.77766 1.43251C2.37984 2.3665 1.29037 3.69402 0.647028 5.24719C0.00368293 6.80036 -0.164645 8.50943 0.163329 10.1583C0.491303 11.8071 1.30085 13.3217 2.4896 14.5104C3.67834 15.6992 5.1929 16.5087 6.84174 16.8367C8.49057 17.1646 10.1996 16.9963 11.7528 16.353C13.306 15.7096 14.6335 14.6202 15.5675 13.2223C16.5015 11.8245 17 10.1811 17 8.5C17 6.24566 16.1045 4.08365 14.5104 2.48959C12.9163 0.895533 10.7543 0 8.5 0ZM7.44459 4.25C7.44459 3.96821 7.55653 3.69796 7.75578 3.4987C7.95504 3.29944 8.22529 3.1875 8.50709 3.1875C8.78888 3.1875 9.05913 3.29944 9.25839 3.4987C9.45764 3.69796 9.56959 3.96821 9.56959 4.25V9.13041C9.56959 9.26994 9.5421 9.40811 9.48871 9.53702C9.43531 9.66593 9.35705 9.78305 9.25839 9.88172C9.15972 9.98038 9.04259 10.0586 8.91369 10.112C8.78478 10.1654 8.64662 10.1929 8.50709 10.1929C8.36756 10.1929 8.22939 10.1654 8.10048 10.112C7.97158 10.0586 7.85445 9.98038 7.75578 9.88172C7.65712 9.78305 7.57886 9.66593 7.52546 9.53702C7.47207 9.40811 7.44459 9.26994 7.44459 9.13041V4.25ZM8.5 13.8125C8.25904 13.8125 8.02349 13.741 7.82313 13.6072C7.62278 13.4733 7.46662 13.283 7.37441 13.0604C7.2822 12.8378 7.25807 12.5928 7.30508 12.3565C7.35209 12.1201 7.46812 11.9031 7.63851 11.7327C7.8089 11.5623 8.02598 11.4463 8.26232 11.3992C8.49865 11.3522 8.74362 11.3764 8.96624 11.4686C9.18886 11.5608 9.37914 11.7169 9.51301 11.9173C9.64688 12.1176 9.71834 12.3532 9.71834 12.5942C9.71834 12.9173 9.58998 13.2272 9.36149 13.4557C9.13301 13.6841 8.82312 13.8125 8.5 13.8125Z"
                    fill="#E41616"
                  />
                </svg>{" "}
                Invalid Zip Code
              </Box>
            )
          }
          name="zipCode"
          disabled={data.profileLoading}
        />

        <DOB className="dob-field">
          <DobLabel>Date Of Birth *</DobLabel>
          {/* Date */}
          <div style={{ position: "relative" }}>
            {!values?.dayOfBirth && (
              <label
                id={`date-label`}
                className="dateFormateTxt"
                style={{
                  fontSize: "var(--fontSize13)",
                  color: "gray",
                }}
              >
                DD
              </label>
            )}
            <Select
              className="dobTxt"
              labelId={`date-label`}
              value={values?.dayOfBirth}
              disabled={data.profileLoading}
              required
              name="dayOfBirth"
              style={{
                color: "black",
                fontSize: 13,
                textAlign: "center",
              }}
              sx={{ width: 60 }}
              variant="standard"
              onChange={handleChange}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200, // Adjust the max height as needed
                  },
                },
              }}
            >
              {days.map((day, index) => (
                <MenuItem key={index} value={index + 1}>
                  {day}
                </MenuItem>
              ))}
            </Select>
          </div>

          {/* Months */}
          <div style={{ position: "relative" }}>
            {!values?.monthOfBirth && (
              <label
                id={`month-label`}
                className="dateFormateTxt"
                style={{
                  fontSize: "var(--fontSize13)",
                  color: "gray",
                }}
              >
                MM
              </label>
            )}
            <Select
              className="monthField"
              labelId={`month-label`}
              name="monthOfBirth"
              value={values?.monthOfBirth}
              disabled={data.profileLoading}
              sx={{ width: 60, fontSize: 13, textAlign: "center" }}
              variant="standard"
              onChange={handleChange}
              required
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200, // Adjust the max height as needed
                  },
                },
              }}
            >
              {monthNames.map((month, index) => (
                <MenuItem key={index} value={index + 1}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </div>

          {/* Year */}
          <div style={{ position: "relative" }}>
            {!values?.yearOfBirth && (
              <label
                id={`year-label`}
                className="dateFormateTxt"
                style={{
                  fontSize: "var(--fontSize13)",
                  color: "gray",
                }}
              >
                YYYY
              </label>
            )}
            <Select
              className="yearField"
              labelId={`year-label`}
              name="yearOfBirth"
              required
              value={values?.yearOfBirth}
              disabled={data.profileLoading}
              sx={{ width: 70, fontSize: 13, textAlign: "center" }}
              variant="standard"
              onChange={handleChange}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200, // Adjust the max height as needed
                  },
                },
              }}
            >
              {years.map((year, index) => (
                <MenuItem key={index} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </div>
        </DOB>
        <Box display={"flex"} flexDirection={"row"} gridColumn={"1 / span 2"} className="btnsCover">
          <KmnButton className="btn" variant="secondary_free" color="danger" type="unset" onClick={handleDeleteProfile}>
            Delete My Profile
          </KmnButton>
          <Row>
            <ButtonContained
              className="btn"
              size="large"
              sx={{
                height: "65px",
                background: "#979797 !important",
                borderRadius: "5px",
                fontFamily: "Nunito",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "20px",
                fontSize: "var(--fontSize16)",
                textAlign: "center",
                color: "var(--colorBlack)",
                marginRight: "10px",
              }}
              onClick={toggleDrawer ? toggleDrawer(false, false) : handleCancel}
            >
              Cancel
            </ButtonContained>
            <ButtonContained
              variant="contained"
              className="button-auth-main grid-2 btn"
              size="large"
              color="primary"
              type="submit"
            >
              Save
            </ButtonContained>
          </Row>
        </Box>
      </div>
    </form>
  );
};

export default EditProfileForm;
