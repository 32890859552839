import React, { useEffect } from "react";
import date from "../../assets/images/date.svg";

import DoneIcon from "@mui/icons-material/Done";
import useDateFilter from "./useDateFilter";
import { atm_displayNotification, atm_globalFilterValue } from "src/state/atoms";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { FILTER_LOADING_CONFIG } from "src/global/constants";

export const DropdownDate = ({
  setdropdownActive,
  dropdownActive,
  callBackFunction,
  loading
}) => {
  const { data, fns } = useDateFilter();
  const setDisplayNotification = useSetRecoilState(atm_displayNotification);
  const [monthState, setMonthState] = React.useState(false);

  const globalFilterValue = useRecoilValue(atm_globalFilterValue);

  const [headvalue, setHeadValue] = React.useState("");

  useEffect(() => {
    fns.onDateFilterLoad();

    if (data.dateFilter.length > 0) {
      setHeadValue(
        data.dateFilter.find((item) => item.id == globalFilterValue.dateFilter)
          .name
      );
    }
  }, [data.dateFilter]);

  return (
    <div className="DropdownFilter">
      {dropdownActive == "first" && (
        <span
          className="background-bg"
          onClick={(e) => {
            setdropdownActive(null);
          }}
        ></span>
      )}

      <div
        className="head-filter"
        onClick={(e) => {
          setMonthState(!monthState);

          if (dropdownActive == "first") {
            setdropdownActive(null);
          } else {
            setdropdownActive("first");
          }
        }}
      >
        <p>Select Date Range</p>

        <h1>{headvalue}</h1>
        <img src={date} alt="" />
      </div>

      <ul
        className="radio-list"
        style={
          dropdownActive == "first" ? { display: "block" } : { display: "none" }
        }
      >
        {data.dateFilter.map((EachValue, key) => (
          <li key={key}>
            <input
              type="radio"
              id={`${EachValue["id"]}`}
              checked={EachValue.id == globalFilterValue.dateFilter}
              name="month"
              value={EachValue["name"]}
              disabled={loading}
              onChange={(e) => {
                if(loading) return setDisplayNotification(FILTER_LOADING_CONFIG);
                setHeadValue(e.target.value);
                setMonthState(false);
                setdropdownActive(null);
                callBackFunction(e.target.id);
              }}
            />
            <label htmlFor={`${EachValue["id"]}`} className="radio-label">
              <DoneIcon
                fontSize="small"
                style={{ color: "var(--colorWhite)", fontSize: 15 }}
              />
            </label>
            <label htmlFor={`${EachValue["id"]}`}>
              <p>{EachValue["name"]}</p>
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};
