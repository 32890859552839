import axios from "axios"
import { useRecoilState, useSetRecoilState } from "recoil"
import { atm_ManageCategoriesData, atm_ManageCategoriesLoading } from "src/state/atomManageCategories";
import useTransactionsLanding from "../Accounts/Transactions/useTransactionsLanding";
import { atm_categoryUpdatingLoading, atm_isBulkEditMode, atm_transactionsUpdateLoading } from "src/state/atomsTransactionData";
import { getAuthToken } from "src/global/central";

export default function useManegeCategories() {
    const jwt = getAuthToken();
    const setTransactionsUpdateLoading = useSetRecoilState(atm_transactionsUpdateLoading)
    const { fnsTransactions } = useTransactionsLanding()

    let data = {}

    const [categoriesData, setCategoriesData] = useRecoilState(atm_ManageCategoriesData)
    data.categoriesData = categoriesData

    const [categoryLoading, setCategoryLoading] = useRecoilState(atm_ManageCategoriesLoading)
    data.categoryLoading = categoryLoading

    const [categoryUpdateLoading, setCategoryUpdateLoading] = useRecoilState(atm_categoryUpdatingLoading);
    data.categoryUpdateLoading = categoryUpdateLoading;

    const setBulkEditMode = useSetRecoilState(atm_isBulkEditMode)

    function getCategoriesData() {
        setCategoryLoading(true)

        let api = process.env.REACT_APP_ACCOUNT_API_END_POINT + "/accounts/transactions/v1/getCategorySubCategoriesForUser"


        axios.post(api, null, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + jwt
            }
        }).then(res => {
            if (res['data']['success'] == false) {
                console.log(
                    'error from server ' + res['data']['responseMessage']
                );
                throw res['data']['responseMessage'];
            }
            else {
                let data = res.data.responseData;
                setCategoriesData(data)
                setCategoryLoading(false)
            }
        })
    }


    function updateCategory(data) {
        setCategoryUpdateLoading(true);
        setTransactionsUpdateLoading(true);
        let api = process.env.REACT_APP_ACCOUNT_API_END_POINT + "/accounts/transactions/v2/updateCategoryForTransaction"

        axios.post(api, {
            "transactionIds": data?.transactionIds,
            "kmnCategoryMasterId": data?.kmnCategoryMasterId,
            "topLevelSourceId": data?.topLevelSourceId,
        }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + jwt
            }
        }).then(res => {
            console.log("category updated successfully")
            fnsTransactions.getTransactionsData({isUpdateTransactions: true})
            setBulkEditMode(false);
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            setCategoryUpdateLoading(false);
            setTransactionsUpdateLoading(false);
        })

    }

    let fnsManageCategories = {
        getCategoriesData,
        updateCategory
    }

    return { data, fnsManageCategories }
}