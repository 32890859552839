import React, { memo } from "react";
import {  useSetRecoilState } from "recoil";
import { ArrowDownIcon, ArrowUpIcon } from "src/libs/icons";
import { atm_isAllSelectedInBulkEdit } from "src/state/atomsTransactionData";

const CheckIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6666 3.5L5.24992 9.91667L2.33325 7"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const MinusIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.91675 7H11.0834"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const styles = {
  gridContainer: {
    borderRadius: "4px",
    overflow: "auto",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  th: {
    color: "#1a1a1a",
    padding: "0px 10px",
    height: 56,
    textAlign: "left",
    fontWeight: "bold",
    borderBottom: "1px solid #e0e0e0",
    borderTop: "1px solid #e0e0e0",
    cursor: "pointer",
    position: "sticky",
    top: 0,
  },
  td: {
    position: "relative",
    padding: "20px 10px",
    borderBottom: "1px solid #e0e0e0",
  },
  checkbox: {
    width: 20,
    height: 20,
    cursor: "pointer",
    border: "2px solid var(--color1)",
    borderRadius: "4px",
    appearance: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    backgroundColor: "transparent",
    transition: "all 0.2s",
  },
  checkedCheckbox: {
    backgroundColor: "var(--color1)",
  },
  indeterminateCheckbox: {
    position: "relative",
    width: 20,
    height: 20,
    cursor: "pointer",
    border: "2px solid var(--color1)",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "var(--color1)",
  },
};

const DataGrid = ({
  columns,
  data,
  checkboxSelection = false,
  rowSelectionModel = [],
  onRowSelectionModelChange,
  isRowSelectable,
  getRowClassName,
  sortConfig,
  setSortConfig,
  actualRows
}) => {
  const setIsAllSelectedInBulkEdit = useSetRecoilState(atm_isAllSelectedInBulkEdit)
  const handleRowSelect = (rowId) => {
    const newSelection = rowSelectionModel.includes(rowId)
      ? rowSelectionModel.filter((id) => id !== rowId)
      : [...rowSelectionModel, rowId];

    onRowSelectionModelChange?.(newSelection);

    setIsAllSelectedInBulkEdit(newSelection.length >= actualRows.length);
  };

  const handleSelectAll = () => {
    const allRowIds = actualRows
      .map((row) => {
        return isRowSelectable({ id: row.id, row }) ? row.id : null;
      })
      .filter(Boolean);

    const newSelection = rowSelectionModel.length > 0 ? [] : allRowIds;

    onRowSelectionModelChange?.(newSelection);

    setIsAllSelectedInBulkEdit(newSelection.length >= actualRows.length);
  };

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return (
    <div style={styles.gridContainer} className="dataGrid">
      <table style={styles.table}>
        <thead>
          <tr>
            {checkboxSelection && (
              <th style={styles.th}>
                {rowSelectionModel.length > 0 &&
                rowSelectionModel.length < actualRows.length ? (
                  <div
                    style={styles.indeterminateCheckbox}
                    onClick={handleSelectAll}
                    role="checkbox"
                    aria-checked="mixed"
                    tabIndex={0}
                  >
                    <MinusIcon />
                  </div>
                ) : (
                  <div
                    style={{
                      ...styles.checkbox,
                      ...(rowSelectionModel.length === actualRows.length
                        ? styles.checkedCheckbox
                        : {}),
                    }}
                    onClick={handleSelectAll}
                    role="checkbox"
                    aria-checked={rowSelectionModel.length === actualRows.length}
                    tabIndex={0}
                  >
                    {rowSelectionModel.length === actualRows.length && <CheckIcon />}
                  </div>
                )}
              </th>
            )}
            {columns.map((column) => (
              <th
                key={column.field}
                onClick={() => column.sortable && requestSort(column.field)}
                style={{ ...styles.th }}
                className={column.headerClassName}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 3,
                  }}
                >
                  {column.renderHeader ? column.renderHeader() : column.field}
                  {sortConfig.key === column.field && column.sortable && (
                    <div>
                      {sortConfig.direction === "ascending" ? (
                        <ArrowUpIcon className="filterIcon" />
                      ) : (
                        <ArrowDownIcon className="filterIcon" />
                      )}
                    </div>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr
              key={index}
              className={
                getRowClassName ? getRowClassName({ id: row.id, row }) : ""
              }
            >
              {checkboxSelection && (
                <td
                  style={{
                    ...styles.td,
                    backgroundColor:
                      rowSelectionModel.includes(row.id) &&
                      isRowSelectable({ id: row.id, row })
                        ? "rgba(25, 118, 210, 0.08)"
                        : undefined,
                  }}
                >
                  <div
                    style={{
                      ...styles.checkbox,
                      ...(rowSelectionModel.includes(row.id) &&
                      isRowSelectable({ id: row.id, row })
                        ? styles.checkedCheckbox
                        : {}),
                    }}
                    onClick={() =>
                      isRowSelectable({ id: row.id, row }) &&
                      handleRowSelect(row.id)
                    }
                    role="checkbox"
                    aria-checked={
                      rowSelectionModel.includes(row.id) &&
                      isRowSelectable({ id: row.id, row })
                    }
                    tabIndex={0}
                  >
                    {rowSelectionModel.includes(row.id) &&
                      isRowSelectable({ id: row.id, row }) && <CheckIcon />}
                  </div>
                </td>
              )}
              {columns.map((column) => (
                <td
                  key={column.field}
                  style={{
                    ...styles.td,
                    minWidth: column.minWidth,
                    backgroundColor:
                      rowSelectionModel.includes(row.id) &&
                      isRowSelectable({ id: row.id, row })
                        ? "rgba(25, 118, 210, 0.08)"
                        : undefined,
                  }}
                  className={column.cellClassName}
                >
                  <div className="cell">
                    {column.renderCell
                      ? column.renderCell({ row, value: row[column.field] })
                      : row[column.field]}
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default memo(DataGrid);
