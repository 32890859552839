import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import useSpendByCategory from "./useSpendByCategory";
import { useRecoilValue } from "recoil";
import { atm_InsightsMerchantList4Category } from "src/state/insightsCategories";
import { usdFormate } from "src/global/central";
import { LinearProgress } from "@mui/material";

const InsightTable = () => {
  const { data } = useSpendByCategory();
  const [gridHeight, setGridHeight] = useState(null);
  const merchantList4SelectedCategory = useRecoilValue(
    atm_InsightsMerchantList4Category
  );

  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (merchantList4SelectedCategory) {
      const rowsData = merchantList4SelectedCategory.map((merchant, index) => {
        const categorySpend = data?.selectedCategoryAmount || 0;
        const percentage = (
          (merchant.txnTotal / categorySpend) * 100
        ).toFixed(2);

        return {
          id: index + 1,
          col1: merchant.merchantName,
          col2: merchant.avgUserRatings || 0,
          reviewCount: merchant.totalUserWithRatings || 0,
          totalSpend: merchant.txnTotal,
          percentageSpend: +percentage,
          logo: merchant.merchantURL,
        };
      });

      setRows(rowsData);
    }
  }, [merchantList4SelectedCategory]);

  const columns = [
    {
      field: "id",
      headerName: "# (S.NO)",
      minWidth: 100,
      cellClassName: "id-cell",
      headerClassName: "head-cell",
      justify: "center"
    },
    {
      field: "col1",
      headerName: "MERCHANT NAME",
      minWidth: 250,
      flex: 1,
      headerClassName: "head-cell",
      cellClassName: "merchent-cell cell",
      renderCell: (row) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          {row?.row?.logo && <img height={30} srcSet={row?.row?.logo} alt="" />}
          <span>{row.value}</span>
        </Box>
      ),
    },
    {
      field: "col2",
      headerName: "RATING",
      minWidth: 200,
      flex: 1,
      headerClassName: "head-cell",
      cellClassName: "rating-cell cell",
      renderCell: (row) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Rating value={row.value} precision={0.5} readOnly />
          <span style={{ marginLeft: 5, fontSize: 16, fontWeight: 700 }}>
            {row.row.reviewCount}
          </span>
        </Box>
      ),
    },
    {
      field: "totalSpend",
      headerName: "Total Spend",
      minWidth: 150,
      flex: 1,
      headerClassName: "head-cell",
      cellClassName: "spend-cell cell",
      valueGetter: (value) => `${usdFormate(value)}`,
    },
    {
      field: "percentageSpend",
      headerName: "% SPEND",
      minWidth: 120,
      flex: 1,
      headerClassName: "head-cell",
      cellClassName: "percent-spend cell",
      renderCell: (row) => {
        return <div style={{ fontWeight: 600 }}>{row.value}%</div>
      },
    },
  ];

  return (
    <div className="insightTable categoryInsight" style={{ width: "100%" }}>
      {data.merchantDataLoading && (
        <LinearProgress
          style={{ width: "100%", position: "absolute", top: 0, left: 0 }}
        />
      )}

      <DataGrid
        rows={rows}
        columns={columns}
        getRowHeight={() => "auto"}
        autoHeight
        onResize={(e) => {
          setGridHeight(e?.height)
        }}
        resizeThrottleMs={100}
        sx={{
          borderLeft: 0,
          borderRight: 0,
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: "percentageSpend", sort: "desc" }],
          },
        }}
        disableRowSelectionOnClick
        hideFooter
      />
    </div>
  );
};

export default InsightTable;
